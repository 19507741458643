/* global MatgenGlobal, $ */

import MatgenUIFunctions from '../../matgen-ui/ui-functions.js';

function arrayToCsv(data) {
  return data
    .map(
      row =>
        row
          .map(String) // convert every value to String
          .map(v => v.replaceAll('"', '""')) // escape double quotes
          .map(v => `"${v}"`) // quote it
          .join(',') // comma-separated
    )
    .join('\r\n'); // rows starting on new lines
}

function downloadBlob(content, filename, contentType) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}

export default {
  test: {
    hash: 'test',
    route: '/test',
    action: async () => {
      const templates = await MatgenGlobal.Data.getTemplates(
        '3ef2b6ec-f02a-4b3c-9864-9d0d02f124fd',
        true
      );

      //let distinctNames = [];
      //templates = [templates[0], templates[1], templates[2]];

      const pageJson = [];
      for (let i = 0; i < templates.length; i++) {
        const pages = await MatgenGlobal.Data.getPages(templates[i].id);
        templates[i].pages = pages;
        for (let j = 0; j < pages.length; j++) {
          const json = await MatgenGlobal.Data.getTemplateFile(pages[j].id);
          pageJson.push({
            template_id: templates[i].id,
            template_name: templates[i].name,
            page_id: pages[j].id,
            json,
          });
        }
      }

      const rows = [];

      for (let i = 0; i < pageJson.length; i++) {
        for (let j = 0; j < pageJson[i].json.objects.length; j++) {
          const o = pageJson[i].json.objects[j];
          const p = pageJson[i];
          if (o.name && !o.richText) {
            /*if (!distinctNames.includes(o.name.toLowerCase())) {
              distinctNames.push(o.name.toLowerCase());
            }*/
            if (
              o.name.toLowerCase().includes('headline') ||
              o.name.toLowerCase().includes('subhead') ||
              o.name.toLowerCase().includes('sub-head') ||
              o.name.toLowerCase().includes('headline and text') ||
              o.name.toLowerCase().includes('headline and copy') ||
              o.name.toLowerCase().includes('body')
            ) {
              const opts = await MatgenGlobal.Data.getComponentOptions(
                o.componentId
              );
              for (let k = 0; k < opts.length; k++) {
                const item = await MatgenGlobal.Data.getComponentOption(
                  opts[k].id,
                  '3ef2b6ec-f02a-4b3c-9864-9d0d02f124fd'
                );
                if (item && item.objects) {
                  for (let l = 0; l < item.objects.length; l++) {
                    if (item.objects[l].text) {
                      rows.push([
                        p.template_id,
                        p.template_name,
                        o.name,
                        item.objects[l].text,
                      ]);
                    }
                  }
                } else if (item && item.text) {
                  rows.push([
                    p.template_id,
                    p.template_name,
                    o.name,
                    item.text,
                  ]);
                }
              }
            }
          }
        }
      }

      const out = arrayToCsv(rows);
      downloadBlob(out, 'nia-template-text.csv', 'text/csv');

      MatgenGlobal.UI.showPage(
        '<div id="test-div" style="width:100%;margin:auto;">Test</div>'
      );
    },
  },
  templates: {
    hash: 'templates',
    route: '/templates',
    action: () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tenant_id = urlParams.get('tenant_id');
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (tenant_id) {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MaterialTemplateTable.getHTML(),
          async () => {
            const data = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading template table data',
              promise: MatgenGlobal.Data.getTemplates(tenant_id),
            });
            MatgenGlobal.Tables.MaterialTemplateTable.load(data);
          }
        );
      } else {
        if (
          MatgenGlobal.AuthUser.user &&
          MatgenGlobal.AuthUser.getUserRole() === 'user'
        ) {
          MatgenGlobal.Router.goTo('/home');
        } else {
          MatgenGlobal.UI.showPage(
            MatgenGlobal.Tables.TemplateTable.getHTML(),
            () => {
              if (
                MatgenGlobal.AuthUser.getUserRole() === 'admin' &&
                MatgenGlobal.Tables.TemplateTable.columns.findIndex(
                  c => c.data === 'tenant_name'
                ) >= 0
              ) {
                MatgenGlobal.Tables.TemplateTable.columns.splice(
                  MatgenGlobal.Tables.TemplateTable.columns.findIndex(
                    c => c.data === 'tenant_name'
                  ),
                  1
                );
                MatgenGlobal.Tables.TemplateTable.columns.find(
                  c => c.data === 'id'
                ).visible = false;
              }
              if (MatgenGlobal.AuthUser.getUserRole() === 'super') {
                $(`
                  <button id="template-import-button" type="button" data-action="import-template" class="btn btn-primary float-end table-action" data-bs-toggle="tooltip" data-placement="auto" title="" data-bs-original-title="Import template" style="margin-right:.5em;">Import</button>
                `).insertBefore($('button[data-action="new-template"]'));
              }
              MatgenGlobal.Tables.TemplateTable.load();
            }
          );
        }
      }
    },
  },
  tenants: {
    hash: 'tenants',
    route: '/tenants',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.TenantTable.getHTML(),
          () => {
            MatgenGlobal.Tables.TenantTable.load();
          }
        );
      }
    },
  },
  matgenQuestionnaire: {
    hash: 'matgenQuestionnaires',
    route: '/matgen-questionnaires',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MatgenQuestionnaireTable.getHTML(),
          () => {
            MatgenGlobal.Tables.MatgenQuestionnaireTable.load();
          }
        );
      }
    },
  },
  userContentQuestionnaire: {
    hash: 'userContentQuestionnaires',
    route: '/user-content-questionnaires',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.UserContentQuestionnaireTable.getHTML(),
          () => {
            MatgenGlobal.Tables.UserContentQuestionnaireTable.load();
          }
        );
      }
    },
  },
  section: {
    hash: 'sections',
    route: '/sections',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.SectionsTable.getHTML(),
          () => {
            MatgenGlobal.Tables.SectionsTable.load();
          }
        );
      }
    },
  },
  questions: {
    hash: 'questions',
    route: '/questions',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.QuestionsTable.getHTML(),
          () => {
            MatgenGlobal.Tables.QuestionsTable.load();
          }
        );
      }
    },
  },
  answers: {
    hash: 'answers',
    route: '/answers',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.AnswersTable.getHTML(),
          () => {
            MatgenGlobal.Tables.AnswersTable.load();
          }
        );
      }
    },
  },
  templateSummary: {
    hash: 'template-summary',
    route: '/template-summary',
    action: () => {
      MatgenGlobal.UI.showPage(
        MatgenGlobal.Tables.MaterialTemplateTable.getHTML(),
        async () => {
          const data = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading template table data',
            promise: MatgenGlobal.Data.getTemplates(),
          });
          MatgenGlobal.Tables.TemplateSummaryTable.load(data);
        }
      );
    },
  },
  users: {
    hash: 'users',
    route: '/users',
    action: () => {
      MatgenGlobal.Router.core.emitRouteEvent('users');
      if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.userManagement();
      }
    },
  },
  templateEdit: {
    hash: 'templates/:id',
    route: '/templates/:id',
    after: () => {
      $(MatgenGlobal.ControllerTargetSelector).addClass('editor');
    },
    leave: done => {
      $(MatgenGlobal.ControllerTargetSelector).removeClass('editor');
      $('#sidebar').remove();
      done();
    },
    action: req => MatgenGlobal.UI.showTemplateEditor(req.id),
  },
  tenantEdit: {
    hash: 'tenant/:id',
    route: '/tenant/:id',
    action: req => MatgenGlobal.UI.showTenantEditor(req.id),
  },
  tenantQuestionnaire: {
    hash: 'tenant-questionnaire',
    route: '/tenant-questionnaire',
    action: () => MatgenUIFunctions.createQuestionnaire(),
  },
  editQuestionnaire: {
    hash: 'edit-questionnaire/:id',
    route: '/edit-questionnaire/:id',
    action: req => MatgenUIFunctions.editQuestionnaire(req.id),
  },
  tags: {
    hash: 'tags/:id',
    route: '/tags/:id',
    action: req => MatgenGlobal.UI.loadTagger(req.id),
  },
  inspector: {
    hash: 'inspect/:id',
    route: '/inspect/{id}',
    action: req => MatgenGlobal.UI.loadInspector(req.id),
  },
  optionOrder: {
    hash: 'option-order/:id',
    route: '/option-order/{id}',
    action: req => MatgenGlobal.UI.loadOptionSort(req.id),
  },
  materials: {
    hash: 'materials',
    route: '/materials',
    action: () => {
      if (MatgenGlobal.AuthUser && MatgenGlobal.AuthUser.user) {
        MatgenGlobal.Router.core.emitRouteEvent('materials');

        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MaterialTable.getHTML(),
          () => {
            MatgenGlobal.Tables.MaterialTable.load();
          }
        );
      } else {
        MatgenGlobal.Router.goTo('unauth');
      }
    },
  },
  microsites: {
    hash: 'microsites',
    route: '/microsites',
    action: () => {
      MatgenGlobal.Router.core.emitRouteEvent('microsites');
      MatgenGlobal.UI.showPage(
        MatgenGlobal.Tables.MicrositeTable.getHTML(),
        () => {
          MatgenGlobal.Tables.MicrositeTable.load();
        }
      );
    },
  },
  materialEdit: {
    hash: 'materials/:id',
    route: '/materials/:id',
    action: req => MatgenGlobal.UI.showMaterialEditor(req.id),
  },
  unauth: {
    hash: 'unauth',
    route: '/unauth',
    action: () => MatgenGlobal.UI.unauth(),
  },
  appError: {
    hash: 'error',
    route: '/error',
    action: () => MatgenGlobal.UI.appError(),
  },
  library: {
    hash: 'library',
    route: '/library',
    action: () => MatgenGlobal.UI.library(),
  },
};
