/* global $, MatgenGlobal, M4CGlobal */

import { emit } from '../../common/helpers.js';
import { debounce } from 'throttle-debounce';
import { v4 as UUID } from 'uuid';
import MatgenUIFunctions from '../../ui-functions.js';

const debouncedResize = debounce(100, false, () => {
  resizeHandler();
});

function resizeHandler() {
  MatgenGlobal.UI.resizeHandler();
}

const init = () => {
  $(window).resize(() => {
    debouncedResize();
  });

  $(document).off('click', '#study-data-text-form-submit');
  $(document).on('click', '#study-data-text-form-submit', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      const curbObjId = $('#curObjId').val();
      const curObj = MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .find(o => o.id === curbObjId);
      if (curObj) {
        curObj.text = $('#inputText').val();
        curObj.fontSize = $('#inputFontSize').val();
        MatgenGlobal.editor.cur().fabric.renderAll();
      } else {
        MatgenGlobal.UI.handleError(
          'Object not found',
          'There is a problem finding the object on the canvas.'
        );
      }
      $('#study-data-text-modal').modal('hide');
    }
  });

  $(document).off('matgen-sidebar-loading-stop');
  $(document).on('matgen-sidebar-loading-stop', () => {
    if (MatgenGlobal.focusElement) {
      MatgenGlobal.editor
        .cur()
        .fabric.setActiveObject(MatgenGlobal.focusElement);
      delete MatgenGlobal.focusElement;
    }
  });

  $(document).off('click', '.user-toggle-object');
  $(document).on('click', '.user-toggle-object', async e => {
    e.preventDefault();
    e.stopPropagation();
    const id = $(e.currentTarget).attr('data-id');
    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const obj = objects.filter(o => o.id === id);
    const treeli = $(e.currentTarget)
      .parent()
      .parent()
      .parent();
    if ($(e.currentTarget).attr('data-hidden') !== 'true') {
      for (let i = 0; i < obj.length; i++) {
        obj[i].visible = false;
      }
      $(e.currentTarget).html('Show <i class="fa-regular fa-eye fa-sm"></i>');
      $(e.currentTarget).attr('data-hidden', 'true');
      treeli.addClass('matgen-disabled');
    } else {
      for (let i = 0; i < obj.length; i++) {
        obj[i].visible = true;
      }
      $(e.currentTarget).html(
        'Hide <i class="fa-regular fa-eye-slash fa-sm"></i>'
      );
      $(e.currentTarget).attr('data-hidden', 'false');
      treeli.removeClass('matgen-disabled');
    }

    MatgenGlobal.editor.cur().fabric.renderAll();
  });

  $(document).off('click', '#matgen-resend-confirmation-link-submit');
  $(document).on('click', '#matgen-resend-confirmation-link-submit', e => {
    e.preventDefault();
    $('#matgen-resend-confirmation-link-modal').modal('hide');
    MatgenGlobal.AuthUserModule.resendConfirmationCode(
      $('#matgen-resend-confirmation-link-email').val()
    );
  });

  $(document).off('click', '#matgen-resend-confirmation-link');
  $(document).on('click', '#matgen-resend-confirmation-link', e => {
    e.preventDefault();
    $('#matgen-signin-modal').modal('hide');
    emit({ event: 'matgen-close-login-modal' });
    MatgenGlobal.UI.resendVerificationModal();
  });

  $(document).off('click', '#m4c-login, #m4c-save-prompt-login');
  $(document).on('click', '#m4c-login, #m4c-save-prompt-login', e => {
    e.preventDefault();
    $('#save-prompt-modal').modal('hide');
    MatgenUIFunctions.showLoginModal();
  });

  $(document).off('click keypress', '#m4c-logout');
  $(document).on('click keypress', '#m4c-logout', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      MatgenGlobal.AuthUser.signOut(false, () => {
        window.location.reload();
      });
    }
  });

  $(document).off('click keypress', '#m4c-mfa-disable');
  $(document).on('click keypress', '#m4c-mfa-disable', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Disabling MFA',
          promise: MatgenGlobal.Amplify.Auth.setPreferredMFA(
            MatgenGlobal.AuthUser.user,
            'NOMFA'
          ),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'API error',
          'There was an error changing your MFA settings, please try again or contact support.'
        );
      }
    }
  });

  $(document).off('click keypress', '#m4c-mfa');
  $(document).on('click keypress', '#m4c-mfa', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      MatgenGlobal.UI.mfaSetupModal();
    }
  });

  $(document).off('click keypress', '#copy-mfa-code');
  $(document).on('click keypress', '#copy-mfa-code', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      navigator.clipboard.writeText($('#mfa-text-code').text());
      MatgenGlobal.UI.alertModal(
        'Code copied',
        `<p>MFA code copied to clipboard.</p>`
      );
    }
  });

  $(document).off('click keypress', '#matgen-mfa-totp-submit');
  $(document).on('click keypress', '#matgen-mfa-totp-submit', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#mfa-error-message').empty();
      const code = $('#matgen-mfa-totp').val();
      if (code && code !== '') {
        let user;
        try {
          user = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading current user',
            group: 'totp-submit',
            promise: MatgenGlobal.Amplify.Auth.currentAuthenticatedUser(),
          });
        } catch (e) {
          console.error(e);
          $('#mfa-error-message')
            .empty()
            .append(
              '<p>There was an error retrieving the user info. Please try again or contact the help desk.</p>'
            );
        }

        try {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Verifying TOTP code',
            promise: MatgenGlobal.Amplify.Auth.verifyTotpToken(user, code),
          });
        } catch (e) {
          console.error(e);
          $('#mfa-error-message')
            .empty()
            .append(
              '<p>There was an error verifying the token or the token is invalid. Please try again or contact the help desk.</p>'
            );
        }

        try {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Saving preferred MFA',
            promise: MatgenGlobal.Amplify.Auth.setPreferredMFA(user, 'TOTP'),
          });
        } catch (e) {
          console.error(e);
          $('#mfa-error-message')
            .empty()
            .append(
              '<p>There was an error finalizing your MFA settings. Please try again or contact the help desk.</p>'
            );
        }
      } else {
        $('#mfa-error-message')
          .empty()
          .append(
            '<p>You must enter the TOTP code from your chosen MFA application.</p>'
          );
      }
    }
  });

  $(document).off('click', '#matgen-login-submit');
  $(document).on('click', '#matgen-login-submit', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      try {
        const response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Logging in',
          group: 'matgen-login',
          promise: MatgenGlobal.AuthUser.signIn(
            $('#m4c-login-email').val(),
            $('#m4c-login-password').val()
          ),
        });

        if (response === true) {
          $('#matgen-signin-modal').modal('hide');
          emit({ event: 'matgen-close-login-modal' });

          if (
            MatgenGlobal.AuthUser.user.challengeName === 'SOFTWARE_TOKEN_MFA'
          ) {
            MatgenGlobal.UI.totpModal();
          } else {
            if (
              MatgenGlobal.AuthCallback &&
              typeof MatgenGlobal.AuthCallback === 'function'
            ) {
              MatgenGlobal.AuthCallback();
            } else {
              if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
                MatgenGlobal.Router.goTo('templates');
              } else {
                MatgenGlobal.Router.goTo('materials');
              }
            }
          }
        } else if (response === 'NEW_PASSWORD_REQUIRED') {
          $('#matgen-signin-modal').modal('hide');
          emit({ event: 'matgen-close-login-modal' });
          MatgenGlobal.UI.changePassword();
        } else if (response === 'RESET_PASSWORD_REQUIRED') {
          $('#matgen-signin-modal').modal('hide');
          emit({ event: 'matgen-close-login-modal' });
          MatgenGlobal.UI.forgotPassword();
        } else if (response === 'UNCONFIRMED_EMAIL') {
          $('#matgen-resend-confirmation-link').show();
          $('#matgen-signin-modal .modal-body')
            .prepend(
              $(
                `
              <div class="alert alert-danger" role="alert">
                User email not verified. Please check your email and click the verification link, or use the resend link below to resend.
              </div>
            `
              )
            )
            .show();
        } else {
          $('#sign-in-error').remove();
          $('#matgen-signin-modal .modal-body').prepend(
            $(`
            <div id="sign-in-error" class="alert alert-danger" role="alert">
              User credentials not recognized. Please check your information and try again.
            </div>
          `)
          );
        }
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Authentication error',
          'There was an error during authentication. Please try again later or contact support.'
        );
      }
    }
  });

  $(document).off('click', '#matgen-totp-submit');
  $(document).on('click', '#matgen-totp-submit', async e => {
    e.preventDefault();
    try {
      MatgenGlobal.AuthUser.user = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Confirming MFA code',
        promise: MatgenGlobal.Amplify.Auth.confirmSignIn(
          MatgenGlobal.AuthUser.user,
          $('#matgen-totp').val(),
          'SOFTWARE_TOKEN_MFA'
        ),
      });
      $('#matgen-totp-modal').modal('hide');
      if (
        MatgenGlobal.AuthCallback &&
        typeof MatgenGlobal.AuthCallback === 'function'
      ) {
        MatgenGlobal.AuthCallback();
      } else {
        if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
          MatgenGlobal.Router.goTo('templates');
        } else {
          MatgenGlobal.Router.goTo('materials');
        }
      }
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'Authentication error',
        'There was an error during authentication. Please try again later or contact support.'
      );
    }
  });

  $(document).off('click', '#m4c-save-prompt-quit');
  $(document).on('click', '#m4c-save-prompt-quit', async e => {
    e.preventDefault();
    $('#save-prompt-modal').modal('hide');
  });

  $(document).off('click', '#m4c-signup, #m4c-save-prompt-signup');
  $(document).on('click', '#m4c-signup, #m4c-save-prompt-signup', async e => {
    e.preventDefault();
    $('#save-prompt-modal').modal('hide');
    MatgenUIFunctions.showSignupModal();
  });

  $(document).off('click', '#m4c-signup-submit');
  $(document).on('click', '#m4c-signup-submit', async e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#signup-error-message').remove();
      const signupForm = document.getElementById('m4c-signup-form');
      MatgenGlobal.AuthUserModule.validateSignup({
        email_element: $('#m4c-signup-email')[0],
        email_confirm_element: $('#m4c-signup-email-confirm')[0],
        password_element: $('#m4c-signup-password')[0],
        password_confirm_element: $('#m4c-signup-password-confirm')[0],
      });
      if (!signupForm.checkValidity()) {
        if (signupForm.reportValidity) {
          signupForm.reportValidity();
        } else {
          alert('Signup form invalid');
        }
      } else {
        const metadata = {};
        if (MatgenGlobal.amplify_config_vars.redirectSignIn) {
          metadata.redirect = MatgenGlobal.amplify_config_vars.redirectSignIn;
        }
        const response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Processing signup',
          promise: MatgenGlobal.AuthUser.signUp(
            document.getElementById('m4c-signup-email').value,
            document.getElementById('m4c-signup-password').value,
            MatgenGlobal.tenant,
            metadata
          ),
        });

        if (response === true) {
          $('#m4c-signup-modal').modal('hide');

          MatgenGlobal.UI.alertModal(
            'Signup complete',
            `
            <p>You have successfully registered. An email has been sent to the submitted address.  <i>If you do not see it, be sure to check your spam or junk folder, as it may have been mistakenly filtered.</i>  You must confirm your email address to complete the signup process.</p>`
          );
          emit({ event: 'matgen-create-account' });
        } else {
          const errDiv = $(
            `<div id="signup-error-message" class="alert alert-danger" role="alert">
              ${response.message}
            </div>`
          );
          errDiv.insertBefore('#m4c-signup-form');
        }
      }
    }
  });

  $(document).off('click', '#sort-question-submit');
  $(document).on('click', '#sort-question-submit', async () => {
    const data = [];
    const sid = $('#sort-list li')
      .first()
      .attr('data-section-id');
    const section = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading section',
      promise: MatgenGlobal.Data.getSection(sid),
    });
    $('#sort-list li').each((i, el) => {
      data.push($(el).attr('data-id'));
    });
    $('#sort-question-modal').modal('hide');
    try {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving sort orders',
        promise: Promise.all(
          data.map((d, i) =>
            MatgenGlobal.Data.saveItem(
              'question',
              {
                id: d,
                sort_order: i,
              },
              true
            )
          )
        ),
      });
      const questions = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading questions',
        promise: MatgenUIFunctions.buildSectionQuestions(
          section.id,
          section.type
        ),
      });

      $(`#${section.type}-questions`)
        .empty()
        .append($(questions));
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'There was a problem saving the sort orders.'
      );
      return false;
    }
  });

  $(document).off('click', '#sort-example-submit');
  $(document).on('click', '#sort-example-submit', async () => {
    const data = [];
    $('#sort-list li').each((i, el) => {
      data.push($(el).attr('data-id'));
    });
    const question_id = $('#sort-list li')
      .first()
      .attr('data-question-id');
    $('#sort-example-modal').modal('hide');
    try {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving sort orders',
        promise: Promise.all(
          data.map((d, i) =>
            MatgenGlobal.Data.saveItem(
              'question_example',
              {
                id: d,
                sort_order: i,
              },
              true
            )
          )
        ),
      });

      const question = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading question',
        promise: MatgenGlobal.Data.getQuestion(question_id),
      });

      const exampleAccordion = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading examples',
        promise: MatgenUIFunctions.buildExampleAccordion(question_id),
      });
      const answerAccordion = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answers',
        promise: MatgenUIFunctions.buildAnswerAccordion(
          question_id,
          MatgenUIFunctions.isJsonAnswer(question.component)
        ),
      });
      $(`#accordion-content-${question_id}`)
        .empty()
        .append($(exampleAccordion))
        .append($(answerAccordion));
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'There was a problem saving the sort orders.'
      );
      return false;
    }
  });

  $(document).off('click', '#sort-answer-submit');
  $(document).on('click', '#sort-answer-submit', async () => {
    const data = [];
    $('#sort-list li').each((i, el) => {
      data.push($(el).attr('data-id'));
    });
    const question_id = $('#sort-list li')
      .first()
      .attr('data-question-id');
    $('#sort-answer-modal').modal('hide');
    try {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving sort orders',
        promise: Promise.all(
          data.map((d, i) =>
            MatgenGlobal.Data.saveItem(
              'answer',
              {
                id: d,
                sort_order: i,
              },
              true
            )
          )
        ),
      });

      const question = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading question',
        promise: MatgenGlobal.Data.getQuestion(question_id),
      });

      const exampleAccordion = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading examples',
        promise: MatgenUIFunctions.buildExampleAccordion(question_id),
      });
      const answerAccordion = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answers',
        promise: MatgenUIFunctions.buildAnswerAccordion(
          question_id,
          MatgenUIFunctions.isJsonAnswer(question.component)
        ),
      });
      $(`#accordion-content-${question_id}`)
        .empty()
        .append($(exampleAccordion))
        .append($(answerAccordion));
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'There was a problem saving the sort orders.'
      );
      return false;
    }
  });

  $(document).off('click', '.sort-section-question-examples');
  $(document).on('click', '.sort-section-question-examples', e => {
    e.preventDefault();
    MatgenUIFunctions.sortExamples($(e.currentTarget).attr('data-question-id'));
  });

  $(document).off('click', '.sort-section-question-answers');
  $(document).on('click', '.sort-section-question-answers', e => {
    e.preventDefault();
    MatgenUIFunctions.sortAnswers($(e.currentTarget).attr('data-question-id'));
  });

  $(document).off('click', '.sort-section-questions');
  $(document).on('click', '.sort-section-questions', e => {
    e.preventDefault();
    MatgenUIFunctions.sortQuestions($(e.currentTarget).attr('data-section-id'));
  });

  $(document).off('click', '.delete-example');
  $(document).on('click', '.delete-example', e => {
    e.preventDefault();
    const id = $(e.currentTarget).attr('data-id');
    MatgenGlobal.UI.confirm(
      'Confirm Delete',
      'Are you sure you wish to delete this example?',
      'Continue',
      'Cancel',
      () => MatgenUIFunctions.deleteQuestionExample(id)
    );
  });

  $(document).off('click', '.delete-answer');
  $(document).on('click', '.delete-answer', e => {
    e.preventDefault();
    const id = $(e.currentTarget).attr('data-id');
    MatgenGlobal.UI.confirm(
      'Confirm Delete',
      'Are you sure you wish to delete this answer?',
      'Continue',
      'Cancel',
      () => MatgenUIFunctions.deleteQuestionAnswer(id)
    );
  });

  $(document).off('click', '.edit-example');
  $(document).on('click', '.edit-example', async e => {
    e.preventDefault();
    MatgenUIFunctions.saveForm(
      'example',
      'Example',
      $(e.currentTarget).attr('data-id')
    );
  });

  $(document).off('click', '.edit-answer');
  $(document).on('click', '.edit-answer', async e => {
    e.preventDefault();
    MatgenUIFunctions.saveForm(
      'answer',
      'Answer',
      $(e.currentTarget).attr('data-id')
    );
  });

  $(document).off('click', '#edit-section-submit');
  $(document).on('click', '#edit-section-submit', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['edit-section-form'];
    const valid = form.validate();
    if (valid === true) {
      const values = form.getValues();
      $('#edit-section-modal').modal('hide');
      const section = {
        id: $('#section-id').val(),
        name: values.find(v => v.id === 'inputName').value,
        title: values.find(v => v.id === 'inputTitle').value,
        sub_text: values.find(v => v.id === 'inputSubtext').value,
      };
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving section',
          promise: MatgenGlobal.Data.saveItem('sections', section, true),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the section.'
        );
        return false;
      }
      $('#goal-detail-s-id').text(section.id);
      $('#goal-detail-s-name').text(section.name);
      $('#goal-detail-s-title').text(section.title);
      $('#goal-detail-s-subtext').text(section.subtext);
    }
  });

  $(document).on('change', '#create-example-form #inputType', async e => {
    e.preventDefault();
    const v = $('#inputType').val();
    if (v === 'richtext') {
      $('#inputText').hide();

      $('#inputRichText').show();
      $('.ql-toolbar').show();
    } else {
      $('#inputRichText').hide();
      $('.ql-toolbar').hide();

      $('#inputText').show();
    }
  });

  $(document).off('click', '.add-section-question-example');
  $(document).on('click', '.add-section-question-example', async e => {
    MatgenUIFunctions.createExample(
      $(e.currentTarget).attr('data-question-id')
    );
  });

  $(document).off('click', '.add-section-question-answer');
  $(document).on('click', '.add-section-question-answer', async e => {
    MatgenUIFunctions.createAnswer($(e.currentTarget).attr('data-question-id'));
  });

  $(document).off('click', '.add-section-question-answer-json');
  $(document).on('click', '.add-section-question-answer-json', async e => {
    MatgenUIFunctions.createAnswerJson(
      $(e.currentTarget).attr('data-question-id')
    );
  });

  $(document).off('click', '#edit-questionnaire-submit');
  $(document).on('click', '#edit-questionnaire-submit', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['edit-questionnaire-form'];
    const valid = form.validate();
    if (valid === true) {
      const values = form.getValues();
      $('#edit-questonnaire-modal').modal('hide');
      const questionnaire = {
        id: $('#questionnaire-id').val(),
        name: values.find(v => v.id === 'inputName').value,
        tenant_id: values.find(v => v.id === 'inputTenantID').value,
        active: values.find(v => v.id === 'inputActive').value,
      };
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving questionnaire',
          promise: MatgenGlobal.Data.saveItem(
            'questionnaires',
            questionnaire,
            true
          ),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the questionnaire.'
        );
        return false;
      }
      const tenant = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading tenant',
        promise: MatgenGlobal.Data.getTenant(questionnaire.tenant_id),
      });
      $('#detail-q-id').text(questionnaire.id);
      $('#detail-q-name').text(questionnaire.name);
      $('#detail-q-type').text(questionnaire.type);
      $('#detail-q-tenant').text(tenant.name);
      $('#detail-q-active').text(questionnaire.active ? 'Y' : 'N');
    }
  });

  $(document).off('click', '.add-section-question');
  $(document).on('click', '.add-section-question', e => {
    e.preventDefault();
    MatgenUIFunctions.createQuestion(
      $(e.currentTarget).attr('data-section-id')
    );
  });

  $(document).off('click', '.q-view');
  $(document).on('click', '.q-view', e => {
    e.preventDefault();
    $('.question-accordion')
      .not(`#accordion-content-${$(e.currentTarget).attr('data-id')}`)
      .hide();
    $(`#accordion-content-${$(e.currentTarget).attr('data-id')}`).toggle();
  });

  $(document).off('click', '.q-edit');
  $(document).on('click', '.q-edit', e => {
    e.preventDefault();
    MatgenUIFunctions.saveForm(
      'question',
      'Question',
      $(e.currentTarget).attr('data-id')
    );
  });

  $(document).off('click', '.q-delete');
  $(document).on('click', '.q-delete', e => {
    e.preventDefault();
    const id = $(e.currentTarget).attr('data-id');
    MatgenGlobal.UI.confirm(
      'Confirm Delete',
      'Are you sure you wish to delete this question? <b class="text-danger">This will delete all the question\'s answers, as well.</b>',
      'Continue',
      'Cancel',
      () => MatgenUIFunctions.deleteSectionQuestion(id)
    );
  });

  $(document).off('click', '#create-question-submit');
  $(document).on('click', '#create-question-submit', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['create-question-form'];
    const valid = form.validate();

    if (valid === true) {
      const sid = $('#q-section-id').val();

      const questions = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading section questions',
        promise: MatgenGlobal.Data.getSectionQuestions(sid),
      });

      const sort_orders = questions.map(q => q.sort_order);

      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      if (!max_sort_order) {
        max_sort_order = 0;
      }
      const question = {
        id: UUID(),
        text: values.find(v => v.id === 'inputText').value,
        sub_text: values.find(v => v.id === 'inputSubtext').value,
        handle: values.find(v => v.id === 'inputHandle').value,
        list_add_name: values.find(v => v.id === 'inputListAddName').value,
        tooltip: values.find(v => v.id === 'inputTooltip').value,
        instructions: JSON.stringify({
          html: M4CGlobal.quill.instructionsRTE.root.innerHTML,
          plain: M4CGlobal.quill.instructionsRTE.getText(),
          data: M4CGlobal.quill.instructionsRTE.getContents(),
        }),
        component: values.find(v => v.id === 'inputComponent').value,
        section_id: sid,
        sort_order:
          values.find(v => v.id === 'inputSortOrder').value == ''
            ? max_sort_order + 1
            : values.find(v => v.id === 'inputSortOrder').value,
      };
      $('#create-question-modal').modal('hide');
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving question',
          promise: MatgenGlobal.Data.saveItem('questions', question),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the question.'
        );
        return false;
      }

      const section = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading section',
        promise: MatgenGlobal.Data.getSection(question.section_id),
      });

      $(`#accordion-${section.type}`)
        .find('.alert')
        .remove();

      const exampleAccordion = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answers',
        promise: MatgenUIFunctions.buildExampleAccordion(question.id),
      });

      const answerAccordion = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answers',
        promise: MatgenUIFunctions.buildAnswerAccordion(
          question.id,
          MatgenUIFunctions.isJsonAnswer(question.component)
        ),
      });

      $(`#accordion-${section.type}`).append(
        $(
          MatgenUIFunctions.buildQuestionAccordion(
            question,
            exampleAccordion,
            answerAccordion
          )
        )
      );
    }
  });

  $(document).off('click', '#create-example-submit');
  $(document).on('click', '#create-example-submit', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['create-example-form'];

    const valid = form.validate();

    if (valid === true) {
      const examples = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading examples',
        promise: MatgenGlobal.Data.getQuestionExamples(
          $('#q-question-id').val()
        ),
      });
      const sort_orders = examples.map(q => q.sort_order);
      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      const qid = $('#q-question-id').val();
      if (!max_sort_order) {
        max_sort_order = 0;
      }

      const answerContent =
        $('#inputType').val() === 'richtext'
          ? JSON.stringify({
              html: M4CGlobal.quill.instructionsRTEExamples.root.innerHTML,
              plain: M4CGlobal.quill.instructionsRTEExamples.getText(),
              data: M4CGlobal.quill.instructionsRTEExamples.getContents(),
            })
          : values.find(v => v.id === 'inputText').value;

      const example = {
        id: UUID(),
        answer: answerContent,
        sort_order: max_sort_order + 1,
        type: values.find(v => v.id === 'inputType').value,
        question_id: qid,
      };
      $('#create-example-modal').modal('hide');
      try {
        console.log(example);
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving example',
          promise: MatgenGlobal.Data.saveItem('question-examples', example),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the example.'
        );
        return false;
      }

      const exampleContent = `
        <div id="${example.id}-example" class="example-card">
          <div class="example-title">
            <i class="${example.icon}"></i>
            ${example.answer}
          </div>
          <div style="margin-right:.25em;" class="icon-link edit-example" data-id="${example.id}"><i class="fa-solid fa-pen-to-square"></i></div>
          <div class="icon-link delete-example" data-id="${example.id}"><i class="fa-solid fa-trash-can"></i></div>
        </div>
      `;
      $(`#accordion-${example.question_id}-examples-content`)
        .find('.alert')
        .remove();
      $(`#accordion-${example.question_id}-examples-content`).append(
        $(exampleContent)
      );
    }
  });

  $(document).off('click', '#create-answer-submit');
  $(document).on('click', '#create-answer-submit', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['create-answer-form'];

    const valid = form.validate();

    if (valid === true) {
      const answers = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answers',
        promise: MatgenGlobal.Data.getQuestionAnswers(
          $('#q-question-id').val()
        ),
      });
      const sort_orders = answers.map(q => q.sort_order);
      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      const qid = $('#q-question-id').val();
      if (!max_sort_order) {
        max_sort_order = 0;
      }
      let icon = 'fa-solid fa-circle-question';
      if (MatgenGlobal.ChosenIcon && MatgenGlobal.ChosenIcon.iconName) {
        icon = `${MatgenGlobal.ChosenIcon.prefix} fa-${MatgenGlobal.ChosenIcon.iconName}`;
      }
      const answer = {
        id: UUID(),
        text: values.find(v => v.id === 'inputText').value,
        sub_text: values.find(v => v.id === 'inputSubtext').value,
        icon,
        question_id: qid,
        sort_order: max_sort_order + 1,
      };
      $('#create-answer-modal').modal('hide');
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving answer',
          promise: MatgenGlobal.Data.saveItem('answers', answer),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the answer.'
        );
        return false;
      }
      const answerContent = `
        <div id="${answer.id}-answer" class="answer-card">
          <div class="answer-title">
            <i class="${answer.icon}"></i>
            ${answer.text}
          </div>
          <div style="margin-right:.25em;" class="icon-link edit-answer" data-id="${answer.id}"><i class="fa-solid fa-pen-to-square"></i></div>
          <div class="icon-link delete-answer" data-id="${answer.id}"><i class="fa-solid fa-trash-can"></i></div>
        </div>
      `;
      $(`#accordion-${answer.question_id}-answers-content`)
        .find('.alert')
        .remove();
      $(`#accordion-${answer.question_id}-answers-content`).append(
        $(answerContent)
      );
    }
  });

  $(document).off('click', '#edit-answer-submit');
  $(document).on('click', '#edit-answer-submit', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['create-answer-form'];

    const valid = form.validate();

    if (valid === true) {
      const answers = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answers',
        promise: MatgenGlobal.Data.getQuestionAnswers(
          $('#q-question-id').val()
        ),
      });
      const sort_orders = answers.map(q => q.sort_order);
      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      const qid = $('#q-question-id').val();
      if (!max_sort_order) {
        max_sort_order = 0;
      }
      let icon = 'fa-solid fa-circle-question';
      if (MatgenGlobal.ChosenIcon && MatgenGlobal.ChosenIcon.iconName) {
        icon = `${MatgenGlobal.ChosenIcon.prefix} fa-${MatgenGlobal.ChosenIcon.iconName}`;
      }
      const answer = {
        id: UUID(),
        text: values.find(v => v.id === 'inputText').value,
        sub_text: values.find(v => v.id === 'inputSubtext').value,
        icon,
        question_id: qid,
        sort_order: max_sort_order + 1,
      };
      $('#create-answer-modal').modal('hide');
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving answer',
          promise: MatgenGlobal.Data.saveItem('answers', answer, true),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the answer.'
        );
        return false;
      }
      const answerContent = `
        <div id="${answer.id}-answer" class="answer-card">
          <div class="answer-title">
            <i class="${answer.icon}"></i>
            ${answer.text}
          </div>
          <div style="margin-right:.25em;" class="icon-link edit-answer" data-id="${answer.id}"><i class="fa-solid fa-pen-to-square"></i></div>
          <div class="icon-link delete-answer" data-id="${answer.id}"><i class="fa-solid fa-trash-can"></i></div>
        </div>
      `;
      $(`#accordion-${answer.question_id}-answers-content`)
        .find('.alert')
        .remove();
      $(`#accordion-${answer.question_id}-answers-content`).append(
        $(answerContent)
      );
    }
  });

  $(document).off('change', '.JSONAddRequired');
  $(document).on('change', '.JSONAddRequired', async e => {
    e.preventDefault();

    const question_id = $(e.currentTarget).attr('data-question-id');

    const boxAnswers = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading answers',
      promise: MatgenGlobal.Data.getQuestionAnswers(question_id),
    });

    const answerText = JSON.parse(boxAnswers[0].text);

    answerText['required'] = $(e.currentTarget).is(':checked');

    // console.log(answerText);

    const answers = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading answers',
      promise: MatgenGlobal.Data.getQuestionAnswers($('#q-question-id').val()),
    });
    const sort_orders = answers.map(q => q.sort_order);
    sort_orders.sort();

    const answer = {
      id: boxAnswers[0].id,
      text: JSON.stringify(answerText),
      question_id: question_id,
    };
    $('#create-answer-modal').modal('hide');
    try {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving answer',
        promise: MatgenGlobal.Data.saveItem('answers', answer, true),
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem saving the answer.'
      );
      return false;
    }
    const answerContent = `
        <div id="${answer.id}-answer" class="answer-card">
          <div class="answer-title">
            <i class="${answer.icon}"></i>
            ${answer.text}
          </div>
        </div>
      `;
    $(`#accordion-${answer.question_id}-answers-content`)
      .find('.alert')
      .remove();
    $(`#accordion-${answer.question_id}-answers-content`)
      .empty()
      .append($(answerContent));
  });

  $(document).off('click', '#create-answer-submit-json');
  $(document).on('click', '#create-answer-submit-json', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['create-answer-form'];

    const valid = form.validate();

    if (valid === true) {
      const answers = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answers',
        promise: MatgenGlobal.Data.getQuestionAnswers(
          $('#q-question-id').val()
        ),
      });
      const sort_orders = answers.map(q => q.sort_order);
      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      const qid = $('#q-question-id').val();
      if (!max_sort_order) {
        max_sort_order = 0;
      }

      const answer = {
        id: UUID(),
        text: values.find(v => v.id === 'inputText').value,
        question_id: qid,
        sort_order: max_sort_order + 1,
      };
      $('#create-answer-modal').modal('hide');
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving answer',
          promise: MatgenGlobal.Data.saveItem('answers', answer),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the answer.'
        );
        return false;
      }
      const answerContent = `
        <div id="${answer.id}-answer" class="answer-card">
          <div class="answer-title">
            <i class="${answer.icon}"></i>
            ${answer.text}
          </div>
        </div>
      `;
      $(`#accordion-${answer.question_id}-answers-content`)
        .find('.alert')
        .remove();
      $(`#accordion-${answer.question_id}-answers-content`).append(
        $(answerContent)
      );
    }
  });

  $(document).off('click', '#edit-answer-submit-json');
  $(document).on('click', '#edit-answer-submit-json', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['create-answer-form'];

    const valid = form.validate();

    if (valid === true) {
      const answers = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answers',
        promise: MatgenGlobal.Data.getQuestionAnswers(
          $('#q-question-id').val()
        ),
      });
      const sort_orders = answers.map(q => q.sort_order);
      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      const qid = $('#q-question-id').val();
      if (!max_sort_order) {
        max_sort_order = 0;
      }

      const a = answers[0];

      const answer = {
        id: a.id,
        text: values.find(v => v.id === 'inputText').value,
        question_id: qid,
        sort_order: max_sort_order + 1,
      };
      $('#create-answer-modal').modal('hide');
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving answer',
          promise: MatgenGlobal.Data.saveItem('answers', answer, true),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the answer.'
        );
        return false;
      }
      const answerContent = `
        <div id="${answer.id}-answer" class="answer-card">
          <div class="answer-title">
            <i class="${answer.icon}"></i>
            ${answer.text}
          </div>
        </div>
      `;
      $(`#accordion-${answer.question_id}-answers-content`)
        .find('.alert')
        .remove();
      $(`#accordion-${answer.question_id}-answers-content`)
        .empty()
        .append($(answerContent));
    }
  });

  $(document).off('click', '.edit-questionnaire');
  $(document).on('click', '.edit-questionnaire', e => {
    e.preventDefault();
    MatgenUIFunctions.editQuestionnaireData($(e.currentTarget).attr('data-id'));
  });

  $(document).off('click', '.edit-section');
  $(document).on('click', '.edit-section', e => {
    e.preventDefault();
    MatgenUIFunctions.editSectionData($(e.currentTarget).attr('data-id'));
  });

  $(document).off('click', '.section-toggle');
  $(document).on('click', '.section-toggle', e => {
    e.preventDefault();
    $(`#${$(e.currentTarget).attr('data-id')}`).toggle();
  });

  $(document).off('click', '#create-questionnaire-submit');
  $(document).on('click', '#create-questionnaire-submit', async () => {
    const valid = MatgenGlobal.DynamicForms[
      'create-questionnaire-form'
    ].validate();
    if (valid === true) {
      const tenant_id = $('#inputTenantID').val();
      const name = $('#inputName').val();
      const type = $('#questionnaireType').val();
      const sectionValues = [
        'goal',
        'demographics',
        'distribution',
        'materials',
      ].map(s => {
        return {
          section: s,
          name: $(`#section-${s}-name`).val(),
          title: $(`#section-${s}-title`).val(),
          subtext: $(`#section-${s}-subtext`).val(),
        };
      });
      const newId = UUID();
      $('#create-questonnaire-modal').modal('hide');
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Creating questionnaire',
        promise: MatgenGlobal.Data.saveItem(
          'questionnaires',
          {
            name,
            tenant_id,
            type: type,
            active: 0,
            id: newId,
          },
          false
        ),
      });

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Creating sections',
        promise: Promise.all(
          ['goal', 'demographics', 'distribution', 'materials'].map((s, i) => {
            const item = sectionValues.find(v => v.section === s);
            return MatgenGlobal.Data.saveItem(
              'sections',
              {
                name: item.name,
                questionnaire_id: newId,
                tenant_id,
                type: s,
                active: 0,
                id: UUID(),
                sort_order: i + 1,
                title: item.title,
                subtext: item.subtext,
              },
              false
            );
          })
        ),
      });
      MatgenGlobal.Router.goTo(`edit-questionnaire/${newId}`);
    }
  });

  $(document).off('change', 'select');
  $(document).on('change', 'select', e => {
    if (
      $(e.currentTarget)
        .find('option:selected')
        .hasClass('default')
    ) {
      $(e.currentTarget).css('color', '#aaa');
    } else {
      $(e.currentTarget).css('color', 'inherit');
    }
  });

  $(document).on('show.bs.modal, show-page', () => {
    $('select').each((i, el) => {
      if (
        $(el)
          .find('option:selected')
          .hasClass('default')
      ) {
        $(el).css('color', '#aaa');
      } else {
        $(el).css('color', 'inherit');
      }
    });
  });
  $(document).on('hidden.bs.modal', '#options-modal', () => {
    $('#options-modal').remove();
  });

  $(document).on('click', '#matgen-logout', async () => {
    MatgenGlobal.AuthUser.signOut({ global: true }, () => {
      MatgenGlobal.AuthUI();
    });
  });

  $(document).off('click', '#matgen-login-signup-link');
  $(document).on('click', '#matgen-login-signup-link', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#matgen-signin-modal').modal('hide');
      emit({ event: 'matgen-close-login-modal' });
      MatgenGlobal.UI.signUp();
    }
  });

  $(document).off('click', '#matgen-login-signup-link');
  $(document).on('click', '#matgen-login-signup-link', e => {
    e.preventDefault();
    $('#matgen-signin-modal').modal('hide');
    emit({ event: 'matgen-close-login-modal' });
    MatgenUIFunctions.showSignupModal();
  });

  $(document).off('matgen-signin');
  $(document).on('matgen-signin', () => {
    MatgenGlobal.CommonInit();
    MatgenGlobal.EditorInit();
  });

  $(document).off('click', '#matgen-signup-submit');
  $(document).on('click', '#matgen-signup-submit', async () => {
    $('#matgen-signup-modal').modal('hide');
    const signupForm = document.getElementById('matgen-signup-form');
    MatgenGlobal.AuthUserModule.validateSignup({
      email_element: document.getElementById('matgen-signup-email'),
      email_confirm_element: document.getElementById(
        'matgen-signup-email-confirm'
      ),
      password_element: document.getElementById('matgen-signup-password'),
      password_confirm_element: document.getElementById(
        'matgen-signup-password-confirm'
      ),
    });
    if (!signupForm.checkValidity()) {
      if (signupForm.reportValidity) {
        signupForm.reportValidity();
      } else {
        alert('Signup form invalid');
      }
    } else {
      const signup_response = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Processing sign up',
        promise: MatgenGlobal.AuthUser.signUp(
          document.getElementById('matgen-signup-email').value,
          document.getElementById('matgen-signup-password').value,
          MatgenGlobal.base_config.tenant,
          {
            redirect: MatgenGlobal.base_config.RedirectURL,
            tenant: MatgenGlobal.base_config.tenant,
          }
        ),
      });
      if (
        signup_response === true ||
        signup_response.code === 'UsernameExistsException'
      ) {
        MatgenGlobal.UI.alertModal(
          'Signup Confirmation Sent',
          `
            <div class="alert alert-success" role="alert">
              Signup successful. To log in, you'll need to confirm your email address. Please check your email (allow a few minutes for delivery).
            </div>
          `
        );
      } else {
        MatgenGlobal.UI.handleError('Sign up error', signup_response.message);
      }
    }
  });

  $(document).off('submit', '#matgen-login-form');
  $(document).on('submit', '#matgen-login-form', async e => {
    e.preventDefault();
    const response = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Logging in',
      group: 'matgen-login',
      promise: MatgenGlobal.AuthUser.signIn(
        $('#matgen-login-email').val(),
        $('#matgen-login-password').val()
      ),
    });
    if (
      response === true &&
      (window.location.hash === '' ||
        window.location.hash === '#' ||
        window.location.hash === '#*')
    ) {
      MatgenGlobal.AuthUI();
    } else if (response === 'NEW_PASSWORD_REQUIRED') {
      MatgenGlobal.UI.changePassword();
    } else if (response === 'RESET_PASSWORD_REQUIRED') {
      MatgenGlobal.UI.forgotPassword();
    } else if (response === 'UNCONFIRMED_EMAIL') {
      $('#matgen-resend-confirmation-link').show();
    } else {
      $('#matgen-login-form .form-error')
        .text(response.message)
        .show();
    }
  });

  $(document).off('click keypress', '#matgen-resend-confirmation-submit');
  $(document).on(
    'click keypress',
    '#matgen-resend-confirmation-submit',
    async e => {
      e.preventDefault();
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        $('#matgen-signin-modal').modal('hide');
        emit({ event: 'matgen-close-login-modal' });
        MatgenGlobal.UI.resendConfirmationSubmit();
      }
    }
  );

  $(document).off('click keypress', '#matgen-forgot-password-submit');
  $(document).on(
    'click keypress',
    '#matgen-forgot-password-submit',
    async e => {
      e.preventDefault();
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        MatgenGlobal.UI.validateForm('matgen-forgot-password-form', () => {
          $('#matgen-forgot-password-modal').modal('hide');
          MatgenGlobal.UI.forgotPassword();
        });
      }
    }
  );

  $(document).off('click keypress', '#matgen-forgot-password-code');
  $(document).on('click keypress', '#matgen-forgot-password-code', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $(`#matgen-forgot-password-modal`).modal('hide');
      $('#matgen-signin-modal').modal('hide');
      emit({ event: 'matgen-close-login-modal' });
      MatgenGlobal.UI.confirmPassword();
    }
  });

  $(document).off('click keypress', '#matgen-forgot-password-link');
  $(document).on('click keypress', '#matgen-forgot-password-link', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#matgen-signin-modal').modal('hide');
      emit({ event: 'matgen-close-login-modal' });
      MatgenGlobal.UI.forgotPassword();
    }
  });

  $(document).off('click keypress', '#matgen-reset-password-link');
  $(document).on('click keypress', '#matgen-reset-password-link', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#matgen-signin-modal').modal('hide');
      emit({ event: 'matgen-close-login-modal' });
      MatgenGlobal.UI.confirmPassword();
    }
  });

  $(document).off('click', '#matgen-reset-password-link');
  $(document).on('click', '#matgen-reset-password-link', e => {
    e.preventDefault();
    $('#matgen-signin-modal').modal('hide');
    emit({ event: 'matgen-close-login-modal' });
    MatgenGlobal.UI.confirmPassword();
  });

  $(document).off('click keypress', '#matgen-confirm-password-submit');
  $(document).on(
    'click keypress',
    '#matgen-confirm-password-submit',
    async e => {
      e.preventDefault();
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        MatgenGlobal.UI.confirmPasswordSubmit();
      }
    }
  );

  $(document).off('click keypress', '#matgen-change-password-submit');
  $(document).on(
    'click keypress',
    '#matgen-change-password-submit',
    async e => {
      e.preventDefault();
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        MatgenGlobal.UI.changePasswordSubmit();
      }
    }
  );

  $(document).on('click', () => {
    MatgenGlobal.hideTooltips();
  });
};

export { init };
