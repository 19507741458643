/* global $, MatgenGlobal */

import { emit } from '../matgen-ui/common/helpers.js';

class M4CTable {
  constructor({
    columns = [],
    data = [],
    actions = [],
    tableSelector = '#data-table',
    responsive = true,
    callbacks = {},
    table,
    title,
    arg,
  } = {}) {
    if (!table) {
      console.error('Table name required.');
      return false;
    }
    this.arg = arg;
    this.columns = columns;
    this.data = data;
    this.tableSelector = tableSelector;
    this.table = table;
    this.title = title;
    this.actions = actions;
    this.responsive = responsive;
    this.callbacks = callbacks;
    if (this.callbacks.initComplete) {
      const tmp = this.callbacks.initComplete;
      this.callbacks.initComplete = s => {
        tmp(s);
        emit({ event: 'matgen-page-ready' });
      };
    } else {
      this.callbacks.initComplete = () => {
        emit({ event: 'matgen-page-ready' });
      };
    }
  }

  async load(data = false) {
    if (data === false) {
      try {
        this.data = await MatgenGlobal.MatgenPageLoader.start({
          message: `Loading ${this.table} table data`,
          promise: MatgenGlobal.Data.getTable(this.table, this.arg),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
    } else {
      this.data = data;
    }
    const sortIndex = this.columns.findIndex(c => c.data === 'updated');
    $(this.tableSelector).DataTable({
      data: this.data,
      order: [[sortIndex > -1 ? sortIndex : 0, 'asc']],
      columns: this.columns,
      responsive: this.responsive,
      autoWidth: false,
      ...this.callbacks,
      stateSave: true,
    });
    $('#data-listing-heading').show();
  }

  getCellData(row, cellIndex) {
    return $(this.tableSelector)
      .DataTable()
      .cell(
        $(this.tableSelector)
          .DataTable()
          .row($(row)),
        cellIndex
      )
      .data();
  }

  updateCellData(row, cellIndex, data) {
    $(this.tableSelector)
      .DataTable()
      .cell(
        $(this.tableSelector)
          .DataTable()
          .row($(row)),
        cellIndex
      )
      .data(data);
  }

  static actionsHTML(item, table, actions, alt_id = false) {
    return `
      <div class="table-actions">
        ${actions
          .map(a => {
            if (Array.isArray(a.role)) {
              if (!a.role.includes(MatgenGlobal.AuthUser.getUserRole())) {
                return '';
              }
            } else {
              if (a.role && a.role !== MatgenGlobal.AuthUser.getUserRole()) {
                return '';
              }
            }
            return `
                <button type="button" data-bs-toggle="tooltip" data-placement="auto" data-bs-title="${
                  a.title
                }" title="${a.title}" data-action="${table}-${a.action}"
            ${item.tenant_id ? `data-tenant-id="${item.tenant_id}"` : ''}
            data-id="${item.id}" ${
              alt_id ? `data-alt-id="${item[alt_id]}"` : ''
            } class="btn btn-sm btn-${a.color} table-action ${
              a.classes ? a.classes : ''
            }">
                  <i class="${a.icon}"></i>
                </button>
              `;
          })
          .join('')}
      </div>
    `;
  }

  getHTML() {
    return `
      <div id="data-listing" class="container">
        <div id="data-listing-heading">
          <h1 class="text-center">
            <span>${this.title}</span>
          </h1>
          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? `<button id="table-new-button" type="button" data-action="new-${this.table.replace(
                  /s$/,
                  ''
                )}" class="btn btn-primary float-end table-action"
                 data-bs-toggle="tooltip" data-placement="auto" title="Create new ${this.table.replace(
                   /s$/,
                   ''
                 )}">New</button>`
              : ''
          }
        </div>
        <table id="${this.tableSelector.replace(
          '#',
          ''
        )}" class="table table-striped table-bordered"/>
      </div>
    `;
  }
}

export { M4CTable };
